<template>
  <router-view></router-view>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'BookNursing',
  data() {
    return {}
  },
  computed: {
    ...mapState(['webTitle'])
  },
  components: {
    // 'title-bar': ModuleTitleVue
  }
};
</script>
